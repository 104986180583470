import React, { useEffect } from "react";
import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import { Home } from "./_smoodi/components/Home";
import { MasterLayout } from "./_smoodi/components/Masterlayout";
import CreateSurvey from "./_smoodi/components/Survey/CreateSurvey";
import Thankyou from "./_smoodi/components/Survey/Thankyou";

function App() {
  useEffect(() => {
    const root = document.getElementById("root");
    if (root) {
      root.style.height = "100%";
    }
    return () => {
      if (root) {
        root.style.height = "auto";
      }
    };
  }, []);

  return (
    <div className="App h-100">
      <BrowserRouter>
        <Routes>
          <Route element={<MasterLayout />}>
            <Route path="/" element={<Home />}></Route>
            <Route path="/survey" element={<CreateSurvey />}></Route>
            <Route path="/thankyou" element={<Thankyou />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
