import * as Yup from "yup";
import  { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { submitSurvey } from "../../models/_survey";
import { Button } from "react-bootstrap";

const Rating = (
  props: {
    homeButton: any;
    eventInfo: any;
    ratingSubmitFc: any;
    setCurrentState: any;
    feedbackRequired: boolean;
  }
) => {
  const navigate = useNavigate();
  const { homeButton } = props;
  const { setCurrentState } = props;
  const { feedbackRequired } = props;
  const { eventInfo } = props;
  const { ratingSubmitFc } = props;
  const [rating, setRating] = useState(0);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);

  const validationSchema = Yup.object({
    rating: Yup.number().required("Please select rating"),
  });

  function continueReady(starInt: any) {
    colorStars(starInt);
    setRating(starInt);
    const surveyInput: any = localStorage.getItem("survey_input");
    const inputData = JSON.parse(surveyInput);
    inputData.rating = starInt;
    localStorage.setItem("survey_input", JSON.stringify(inputData));
  }

  function colorStars(starInt: any) {
    for (let i = 0; i < starInt; i++) {
      let star: any = "star" + (i + 1);
      const style: any = document.getElementById(star);
      style.style.filter = "grayscale(0%)";
    }

    for (let i = 5; i > starInt; i--) {
      let star: any = "star" + i;
      const style: any = document.getElementById(star);
      style.style.filter = "grayscale(1000%)";
    }
  }

  return (
    <>
      <div>
        <div className="card mt-9">
          <div className="card-body">
            <div className="d-flex flex-column pt-15">
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  rating: rating
                }}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                  if (rating === 0) {
                    setHasErrors(true);
                    return;
                  } else {
                    const surveyInput: any = localStorage.getItem("survey_input");
                    let inputData = JSON.parse(surveyInput);
                    inputData.rating = rating;
                    localStorage.setItem("survey_input", JSON.stringify(inputData));
                    if (feedbackRequired) {
                      localStorage.setItem("current_state", "feedback");
                      setCurrentState("feedback");
                    } else {
                      if (inputData.questions) {
                        const allQuestions = [];
                        for (const [key, value] of Object.entries(inputData.questions)) {
                          const obj = {
                            question_id: key,
                            answers: typeof value == "object" ? value : [value],
                          };
                          allQuestions.push(obj);
                        }
                        inputData.questions = allQuestions;
                      }
                      await submitSurvey(inputData);
                      localStorage.setItem("current_state", "thankyou");
                      setCurrentState("thankyou");
                      navigate("/thankyou");
                    }
                    setHasErrors(false);
                  }
                  return true;
                }}
              >
                {({ values, handleChange }) => (
                  <Form
                    className="mx-auto mw-800px w-100 pt-15 pb-10"
                    id="kt_create_account_form"
                  >
                    <div className="current" data-kt-stepper-element="content">
                      <div className="d-flex mb-5 justify-content-center">
                        <Button
                          type="button"
                          variant="secondary"
                          className={`fs-1 btn btn-lg btn-light-primary me-3 px-6 py-2`}
                          onClick={() => {
                            homeButton();
                          }}
                        >
                          Home
                        </Button>
                      </div>
                      <div className="row">
                        <h1 className="feedback-header fw-bold">Feedback</h1>
                        <div className="question mt-5 fs-1 pt-5 fw-semibold">
                          How would you rate your smoodi?
                        </div>
                        <div className="mt-5 p-lg-5 p-md-5 p-0">
                          <div className="row justify-content-center align-items-center">
                            <div className="col-12 stars">
                              <button className="mx-xl-3" type="button">
                                <img
                                  id="star1"
                                  src="Images/logo_pineapple.png"
                                  className="stars img-fluid"
                                  width={90}
                                  alt="1 Star"
                                  onClick={() => continueReady(1)}
                                />
                              </button>
                              <button className="mx-xl-3" type="button">
                                <img
                                  id="star2"
                                  src="Images/logo_pineapple.png"
                                  className="stars img-fluid"
                                  width={90}
                                  alt="2 Star"
                                  onClick={() => continueReady(2)}
                                />
                              </button>
                              <button className="mx-xl-3" type="button">
                                <img
                                  id="star3"
                                  src="Images/logo_pineapple.png"
                                  className="stars img-fluid"
                                  width={90}
                                  alt="3 Star"
                                  onClick={() => continueReady(3)}
                                />
                              </button>
                              <button className="mx-xl-3" type="button">
                                <img
                                  id="star4"
                                  src="Images/logo_pineapple.png"
                                  className="stars img-fluid"
                                  width={90}
                                  alt="4 Star"
                                  onClick={() => continueReady(4)}
                                />
                              </button>
                              <button className="mx-xl-3" type="button">
                                <img
                                  id="star5"
                                  src="Images/logo_pineapple.png"
                                  className="stars img-fluid"
                                  width={90}
                                  alt="5 Star"
                                  onClick={() => continueReady(5)}
                                />
                              </button>
                            </div>
                            {hasErrors === true && (
                              <div className="text-danger font-weight-bold mt-4 text-center">
                                Please select rating
                              </div>
                            )}
                            <div className="text-danger fs-6 text-center mt-4">
                              <ErrorMessage name="rating" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mx-lg-9 pt-15 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="fs-6 btn btn-lg btn-primary me-3 px-6 py-2"
                        onClick={() => {
                          ratingSubmitFc(eventInfo);
                        }}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rating;
