import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const toAbsoluteUrl = (pathname: string) =>
  process.env.PUBLIC_URL + pathname;

interface SubmitSurveyInput {
  event_id: string;
  survey_id: string;
  session_id: string;
  questions: [
    {
      question_id: string;
      answers: [];
    }
  ];
}

export async function getEventDetailById(id: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query getEventDetailById($id:String!) {
          getEventDetailById(id: $id) {
            id
            surveyId
            title
            description
            venue
            startDate
            endDate
            survey {
              id
              title
              otherFeedbackRequired
              questions {
                id
                surveyId
                answerType
                question
                fromRange
                toRange
                startLabel
                endLabel
                answers
                isMultiAnswer
              } 
            }
          }
        }
      `,
      variables: {
        id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function submitSurvey(input: SubmitSurveyInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation submitSurvey (
          $input:SubmitSurveyInput!
        ) {
          submitSurvey(
            submitSurveyInput:$input
          )
        }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data?.data?.data?.submitSurvey;
}

export async function verifyEventPin(id: string, pin: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation verifyEventPin (
          $id:String!,
          $pin:String!
        ) {
          verifyEventPin(
            id: $id,
            pin: $pin
          )
        }
      `,
      variables: {
        id: id,
        pin: pin
      },
    },
  }).catch((e: any) => (data = e.response));
  return data?.data?.data?.verifyEventPin;
}
