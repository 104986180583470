import React from "react";
import { toAbsoluteUrl } from "../../models/_survey";

const Thankyou = () => {
  const redirect = () => {
    const link: any = localStorage.getItem("survey_url");
    localStorage.setItem("current_state", "start");
    localStorage.removeItem("survey_input");
    localStorage.removeItem("session_id");
    window.open(`${link}`, "_self");
  };
  const countDown = () => {
    setTimeout(function () {
      redirect();
    }, 5000);
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }} onLoad={countDown}>
        <div className="main-body mt-9 pt-9">
          <header>
            <h1 className="feedback-header fw-bold mt-9 pt-9">Feedback</h1>
          </header>
          <h3 className="text2">
            Thank you for your feedback!
          </h3>
        </div>
        <div className="loader-wrapper">
          <span className="loader" />
        </div>
        <footer className="footer">
          <ul>
            <li>
              <a href="https://www.getsmoodi.com/" target="blank">
                <img
                  alt="logo"
                  src={`${toAbsoluteUrl("/Images/smoodi_Logo.png")}`}
                  width="150vw"
                />
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </>
  );
};

export default Thankyou;
