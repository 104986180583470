import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

const MasterLayout = () => {
  const location = useLocation();
  useEffect(() => {}, [location.key]);

  return (
    <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
      <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
        <div
          className="app-wrapper flex-column flex-row-fluid"
          id="kt_app_wrapper"
        >
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="app-main-content d-flex flex-column flex-column-fluid">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MasterLayout };
