import Rating from "./Rating";
import Feedback from "./Feedback";
import { v4 as uuidv4 } from "uuid";
import { Slider, Modal } from 'antd';
import { Toaster } from "react-hot-toast";
import type { SliderSingleProps } from 'antd';
import { useCallback, useEffect, useState } from "react";
import { Card, Form, Button, Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Field, Form as FormikForm, FieldArray } from "formik";
import { getEventDetailById, toAbsoluteUrl, verifyEventPin } from "../../models/_survey";

const CreateSurvey = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [sessionId, setSessionId] = useState("");
  const [eventInfo, setEventInfo] = useState<any>("");
  const [currentState, setCurrentState] = useState<any>("");
  const [eventError, setEventError] = useState("");
  const [openPinModal, setOpenPinModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [surveyFormPin, setSurveyFormPin] = useState("");
  const [surveyPinError, setSurveyPinError] = useState("");
  const [timeInterval] = useState<any>("");
  const [formData, setFormData] = useState<any>({
    event_id: "",
    survey_id: "",
    session_id: "",
    questions: {},
    rating: 0,
    feedback: "",
  });

  const generateScaleArray = (from: number, to: number, step = 1) => {
    if ([20, 25, 30, 35, 40, 45, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 1000].includes(to)) {
      step = (to - from) / 10;
    }
    const scaleArray = [];
    for (let i = from; i <= to; i += step) {
      scaleArray.push(i);
    }
    return scaleArray;
  }

  const localSessionId = localStorage.getItem("session_id");
  if (!localSessionId) {
    const uniqueId = uuidv4();
    localStorage.setItem("session_id", uniqueId);
    setSessionId(uniqueId);
  } else if (!sessionId) {
    setSessionId(localSessionId);
  }

  const loadStepper =  useCallback(() => {
    const routeId = searchParams.get("id");
    if (!routeId) {
      window.location.href = "https://getsmoodi.com";
    }
  }, [searchParams]);

  const fetchEventDetails = useCallback(async () => {
    try {
      const localState = localStorage.getItem("current_state");
      
      const id: any = searchParams.get("id");
      const result = await getEventDetailById(id);
      const eventData = result?.data?.data?.getEventDetailById;
      if (eventData) {
        setEventInfo(eventData);
        if (new Date() < new Date(eventData.startDate)) {
          setEventError("Event is not started yet.");
        } else if (new Date() > new Date(eventData.endDate)) {
          setEventError("Event is expired now.");
        } else {
          localStorage.setItem("survey_url", window.location.href);
          localStorage.setItem("survey_id", eventData?.surveyId);
          let newData = formData;
          newData.event_id = eventData?.id;
          newData.survey_id = eventData?.surveyId;
          newData.session_id = sessionId;
          localStorage.setItem("survey_input", JSON.stringify(newData));
          setFormData(newData)
          if (localStorage.getItem("event_id") === eventData?.id) {
            setOpenPinModal(false);
            localStorage.setItem("event_id", eventData?.id);
            if (localState !== 'start') {
              setCurrentState("survey");
            }
            } else {
            localStorage.setItem("current_state", "pin");
            setCurrentState("pin");
            setOpenPinModal(true);
          }
        }
      }
    } catch (error) {
      console.log("fetchEventDetails", error);
    }
  }, [searchParams, formData, sessionId]);

  useEffect(() => {
    (async () => {
      loadStepper();
      localStorage.setItem("survey_input", JSON.stringify(formData));
      await fetchEventDetails();
    })();
  }, [loadStepper, fetchEventDetails, formData]);

  useEffect(() => {
    const localState = localStorage.getItem("current_state"); // pin, survey, rating, feedback, thankyou, start
    if (localState === "thankyou") {
      navigate("/thankyou");
    }
    if (localState === 'start') {
      setCurrentState('start')
    }
  }, [navigate]);

  const homeButton = () => {
    clearInterval(timeInterval);
    const link: any = localStorage.getItem("survey_url");
    localStorage.setItem("current_state", "start");
    localStorage.removeItem("survey_input");
    localStorage.removeItem("session_id");
    window.open(`${link}`, "_self");
  }

  const startSurvey = () => {
    clearInterval(timeInterval);
    setCurrentState("survey");
  }

  const previousQuestionFc = () => {
    clearInterval(timeInterval);
  }

  const nextQuestionFc = (eventData: any) => {
    const totalQuestions = eventData?.survey?.questions?.length || 0;
    clearInterval(timeInterval);
    if (currentStep < (totalQuestions - 1)) {
    } else {
      localStorage.setItem("current_state", "rating");
      setCurrentState("rating");
    }
  }

  const questionSubmitFc = () => {
    clearInterval(timeInterval);
    localStorage.setItem("current_state", "rating");
    setCurrentState("rating");
  }

  const ratingSubmitFc = (eventData: any) => {
    clearInterval(timeInterval);
    if (eventData?.survey?.otherFeedbackRequired) {
      localStorage.setItem("current_state", "feedback");
      setCurrentState("feedback");
    }
  }

  const feedbackSubmitFc = () => {
    clearInterval(timeInterval);
  }

  /*
  const countDown = (currentStep: any, eventData: any, type: any) => {
    let counter = 10;
    const totalQuestions = eventData?.survey?.questions?.length || 0;
    const intervalId = setInterval(async () => {
      setTimeInterval(intervalId)
      if (counter > 0) {
        counter -= 1;
      } else {
        clearInterval(intervalId);
        if (type === 'rating') {
          if (eventData?.survey?.otherFeedbackRequired) {
            localStorage.setItem("current_state", "feedback");
            setCurrentState("feedback");
            countDown(totalQuestions, eventData, 'feedback');
          } else {
            const surveyInput: any = localStorage.getItem("survey_input");
            let inputData = JSON.parse(surveyInput);
            if (inputData.questions) {
              const allQuestions = [];
              for (const [key, value] of Object.entries(inputData.questions)) {
                const obj = {
                  question_id: key,
                  answers: typeof value === "object" ? value : [value],
                };
                allQuestions.push(obj);
              }
              inputData.questions = allQuestions;
            }
            await submitSurvey(inputData);

            localStorage.setItem("current_state", "thankyou");
            setCurrentState("thankyou");
            navigate("/thankyou");
          }
        } else if (type === 'feedback') {
          const surveyInput: any = localStorage.getItem("survey_input");
          let inputData = JSON.parse(surveyInput);
          if (inputData.questions) {
            const allQuestions = [];
            for (const [key, value] of Object.entries(inputData.questions)) {
              const obj = {
                question_id: key,
                answers: typeof value === "object" ? value : [value],
              };
              allQuestions.push(obj);
            }
            inputData.questions = allQuestions;
          }
          await submitSurvey(inputData);

          localStorage.setItem("current_state", "thankyou");
          setCurrentState("thankyou");
          navigate("/thankyou");
        } else {
          if (currentStep < (totalQuestions - 1)) {
            setCurrentStep(currentStep + 1)
          } else {
            localStorage.setItem("current_state", "rating");
            setCurrentState("rating");
          }
        }
      }
    }, 1000);
  }; */

  const handleSurveyFormPin = async () => {
    if (!surveyFormPin) {
      setSurveyPinError("Pin is required.");
    }
    const result = await verifyEventPin(eventInfo?.id, surveyFormPin);
    if (result) {
      setSurveyPinError("");
      await fetchEventDetails();
      setOpenPinModal(false);
      localStorage.setItem("event_id", eventInfo?.id);
      localStorage.setItem("current_state", "start");
      setCurrentState("start");
      // countDown(currentStep, eventInfo, 'question');
    } else {
      setSurveyPinError("Invalid Pin.");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
      {eventError ? (
        <div className="card mt-9">
          <div className="card-body">
            <div className="stepper stepper-links d-flex flex-column pt-15">
              <div className="mx-auto mw-800px w-100 pt-15 pb-10">
                <div className="current">
                  <div className="row">
                    <h1 className="feedback-header fw-bold">{eventInfo?.title}</h1>
                    <div className="question text-danger mt-5 pt-5 fw-semibold" style={{ fontSize: "27px" }}>
                      {eventError}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {(currentState === "survey") && (
            <div className="card mt-9">
              <div className="card-body">
                <div className="stepper stepper-links d-flex flex-column pt-15">
                  <div className="mx-auto mw-800px w-100 pt-15 pb-10">
                    {eventInfo && (
                      <div className="current">
                        <div className="d-flex mb-5 justify-content-center">
                          <Button
                            type="button"
                            variant="secondary"
                            className={`fs-1 btn btn-lg btn-light-primary me-3 px-6 py-2`}
                            onClick={() => {
                              homeButton();
                            }}
                          >
                            Home
                          </Button>
                        </div>
                        <div className="row">
                          <h1 className="feedback-header fw-bold">
                            {eventInfo?.title}
                          </h1>
                          {/* <div className="question mt-5 pt-5  fw-semibold" style={{ fontSize: "27px" }}>
                            {eventInfo?.survey?.title}
                          </div> */}
                          <div className="mt-10 p-lg-5 p-md-5 p-0" style={{ border: '1px solid #f5f8fa', borderRadius: '0.625rem', width: '100vh' }}>
                            <div className="row justify-content-center align-items-center" style={{ fontSize: "19px" }}>
                              <Card className="mb-3">
                                <Formik
                                  initialValues={{
                                    event_id: eventInfo?.id,
                                    survey_id: eventInfo?.surveyId,
                                    session_id: sessionId,
                                    questions: {},
                                    rating: 0,
                                    feedback: "",
                                  }}
                                  onSubmit={async (values, { resetForm, setSubmitting }) => {
                                    const $inputData: any = {
                                      event_id: values.event_id,
                                      survey_id: values.survey_id,
                                      session_id: values.session_id,
                                      questions: values.questions,
                                      rating: 0,
                                      feedback: "",
                                    };
                                    localStorage.setItem("survey_input", JSON.stringify($inputData));
                                    localStorage.setItem("current_state", "rating");
                                    setCurrentState("rating");
                                  }}
                                >
                                  {({
                                    values,
                                    setFieldValue,
                                    handleChange,
                                    handleSubmit
                                  }) => (
                                    <FormikForm onSubmit={handleSubmit}>
                                      <input type="hidden" name="event_id" value={eventInfo?.id} />
                                      <input type="hidden" name="survey_id" value={eventInfo?.surveyId} />
                                      <input type="hidden" name="session_id" value={sessionId} />

                                      <div className="mb-4">
                                        {eventInfo?.survey?.questions[currentStep] && (
                                          <>
                                            <h5 style={{ fontSize: "24px", textAlign: "center", marginBottom: "20px" }}>
                                              {currentStep + 1}) {eventInfo.survey.questions[currentStep].question}
                                            </h5>

                                            {eventInfo.survey.questions[currentStep].answerType=== "Button" && (
                                              <Form.Group className="row justify-content-center mt-4">
                                                <FieldArray
                                                  name="questions"
                                                  render={({ push, remove }) => (
                                                    <>
                                                      {eventInfo.survey.questions[currentStep].answers.map((answer: any, idx: number) => {
                                                        let isChecked = false;
                                                        const newValues: any = values;
                                                        let inputType = typeof newValues?.questions?.[eventInfo.survey.questions[currentStep].id];
                                                        let value = newValues?.questions?.[eventInfo.survey.questions[currentStep].id];
                                                        if (inputType !== 'object' && answer.id.toString() === value) {
                                                          isChecked = true;
                                                        } else if (inputType === 'object' && value.includes(answer.id.toString())) {
                                                          isChecked = true;
                                                        }

                                                        return (
                                                          <div className="col-md-5 mb-3" key={idx} style={{ textAlign: "center" }}>
                                                            <label
                                                              htmlFor={`${eventInfo.survey.questions[currentStep].id}-${idx}`}
                                                              className={`${isChecked ? 'answer-button-checked' : 'answer-button'}`}
                                                            >
                                                              <Field
                                                                as={Form.Check}
                                                                type={eventInfo.survey.questions[currentStep].isMultiAnswer ? "checkbox" : "radio"}
                                                                label={answer.answer}
                                                                name={`questions[${eventInfo.survey.questions[currentStep].id}]`}
                                                                id={`${eventInfo.survey.questions[currentStep].id}-${idx}`}
                                                                value={answer.id.toString()}
                                                                onChange={(e: any) => {
                                                                  handleChange(e);
                                                                  const newData: any = values;
                                                                  const id = eventInfo.survey.questions[currentStep].id;
                                                                  if (e.target.type === 'radio') {
                                                                    if (e.target.checked) {
                                                                      newData.questions[id] = e.target.value;
                                                                    } else {
                                                                      newData.questions[id] = '';
                                                                    }
                                                                  } else {
                                                                    if (e.target.checked) {
                                                                      if (newData.questions[id]?.length > 0) {
                                                                        newData.questions[id].push(e.target.value);
                                                                      } else {
                                                                        newData.questions[id] = [e.target.value];
                                                                      }
                                                                    } else {
                                                                      const filteredArray = newData.questions[id].filter((item: any) => item.toString() !== e.target.value.toString());
                                                                      newData.questions[id] = filteredArray;
                                                                      if (filteredArray.length === 0) {
                                                                        delete newData.questions[id];
                                                                      }
                                                                    }
                                                                  }
                                                                  localStorage.setItem("survey_input", JSON.stringify(newData));
                                                                  setFormData(newData);
                                                                }}
                                                                style={{ display: "none" }} // Hide the default checkbox/radio element
                                                              />
                                                              {answer.answer}
                                                            </label>
                                                          </div>
                                                        )
                                                      })}
                                                    </>
                                                  )}
                                                />
                                              </Form.Group>
                                            )}

                                            {eventInfo.survey.questions[currentStep].answerType === "Sliding_Scale" && (() => {
                                              const rangeArray = generateScaleArray(
                                                eventInfo.survey.questions[currentStep].fromRange,
                                                eventInfo.survey.questions[currentStep].toRange
                                              );
                                              const slidingRange: SliderSingleProps['marks'] = rangeArray.reduce((acc: any, value, index) => {
                                                acc[value] = value.toString();
                                                return acc;
                                              }, {});
                                              const newValues: any = values;
                                              const currentValue = newValues?.questions?.[eventInfo.survey.questions[currentStep].id] || '';
                                              return (
                                                <>
                                                  {(eventInfo?.survey?.questions[currentStep]?.startLabel || eventInfo?.survey?.questions[currentStep]?.endLabel) && (
                                                    <Row className="justify-content-between">
                                                      <Col className="text-start">
                                                        <label style={{ fontSize: 'small' }}>
                                                          {eventInfo.survey.questions[currentStep].startLabel}
                                                        </label>
                                                      </Col>
                                                      <Col className="text-end">
                                                        <label style={{ fontSize: 'small' }}>
                                                          {eventInfo.survey.questions[currentStep].endLabel}
                                                        </label>
                                                      </Col>
                                                    </Row>
                                                  )}
                                                  <Row className="justify-content-center">
                                                    <input
                                                      type="hidden"
                                                      name={`questions[${eventInfo.survey.questions[currentStep].id}]`}
                                                      id={`${eventInfo.survey.questions[currentStep].id}`}
                                                    />
                                                    <Col className="text-center">
                                                      <Slider
                                                        styles={{ track: { backgroundColor: "#70c057" } }}
                                                        min={eventInfo.survey.questions[currentStep].fromRange}
                                                        max={eventInfo.survey.questions[currentStep].toRange}
                                                        marks={slidingRange}
                                                        defaultValue={currentValue}
                                                        onChange={(e) => {
                                                          setFieldValue(`questions[${eventInfo.survey.questions[currentStep].id}]`, e.toString())
                                                        }}
                                                      />
                                                    </Col>
                                                  </Row>
                                                </>
                                              );
                                            })()}

                                            {eventInfo.survey.questions[currentStep].answerType === "Numeric_Scale" && (() => {
                                              const rangeArray = generateScaleArray(
                                                eventInfo.survey.questions[currentStep].fromRange,
                                                eventInfo.survey.questions[currentStep].toRange
                                              );
                                              return (
                                                <>
                                                  {(eventInfo?.survey?.questions[currentStep]?.startLabel || eventInfo?.survey?.questions[currentStep]?.endLabel) && (
                                                    <Row className="justify-content-between mb-2">
                                                      <Col className="text-start">
                                                        <label style={{ fontSize: 'small' }}>
                                                          {eventInfo.survey.questions[currentStep].startLabel}
                                                        </label>
                                                      </Col>
                                                      <Col className="text-end">
                                                        <label style={{ fontSize: 'small' }}>
                                                          {eventInfo.survey.questions[currentStep].endLabel}
                                                        </label>
                                                      </Col>
                                                    </Row>
                                                  )}
                                                  <Row className="justify-content-center">
                                                    {rangeArray.map((numericRange, idx: number) => {
                                                      const newValues: any = values;
                                                      const currentValue = newValues?.questions?.[eventInfo.survey.questions[currentStep].id] || '';
                                                      const isChecked = currentValue.toString() === numericRange.toString();
                                                      return (
                                                      <Col key={numericRange} className="text-center">
                                                        <Form.Check
                                                          type="radio"
                                                          name={`questions[${eventInfo.survey.questions[currentStep].id}]`}
                                                          id={`${eventInfo.survey.questions[currentStep].id}-${idx}`}
                                                          value={numericRange}
                                                          checked={isChecked}
                                                          onChange={handleChange}
                                                        />
                                                        <label
                                                          htmlFor={`${eventInfo.survey.questions[currentStep].id}-${idx}`}
                                                          style={{fontSize: '14px', color: !isChecked ? 'rgba(0, 0, 0, 0.45)' : ''}}
                                                        >
                                                          {numericRange}
                                                        </label>
                                                      </Col>
                                                    )})}
                                                  </Row>
                                                </>
                                              );
                                            })()}
                                          </>
                                        )}
                                      </div>

                                      <div className={`d-flex ${currentStep !== 0 ? "justify-content-between" : "justify-content-end"}`}>
                                        {/* <div className={`d-flex justify-content-end`}> */}
                                        {currentStep !== 0 && (
                                          <Button
                                            type="button"
                                            variant="secondary"
                                            className={`fs-6 btn btn-lg btn-light-primary me-3 px-6 py-2`}
                                            onClick={() => {
                                              setCurrentStep(currentStep - 1);
                                              previousQuestionFc();
                                            }}
                                          >
                                            Previous
                                          </Button>
                                        )}

                                        {(currentStep === (eventInfo.survey.questions.length - 1)) && (
                                          <Button
                                            type="submit"
                                            variant="primary"
                                            onClick={() => {
                                              handleSubmit();
                                              questionSubmitFc();
                                            }}
                                          >
                                            Submit
                                          </Button>
                                        )}

                                        {(currentStep !== (eventInfo.survey.questions.length - 1)) && (
                                          <Button
                                            type="button"
                                            variant="primary"
                                            onClick={() => {
                                              if (currentStep < eventInfo.survey.questions.length - 1) {
                                                setCurrentStep(currentStep + 1);
                                              }
                                              nextQuestionFc(eventInfo);
                                            }}
                                            disabled={currentStep === eventInfo.survey.questions.length - 1}
                                          >
                                            Next
                                          </Button>
                                        )}
                                      </div>
                                    </FormikForm>
                                  )}
                                </Formik>
                              </Card>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {currentState === "rating" && (
            <Rating
              homeButton={homeButton}
              eventInfo={eventInfo}
              ratingSubmitFc={ratingSubmitFc}
              setCurrentState={setCurrentState}
              feedbackRequired={eventInfo?.survey?.otherFeedbackRequired}
            />
          )}

          {currentState === "feedback" && (
            <Feedback
              homeButton={homeButton}
              eventInfo={eventInfo}
              feedbackSubmitFc={feedbackSubmitFc}
              setCurrentState={setCurrentState}
            />
          )}

          {currentState === "start" && (
            <div className="card mt-9">
              <div className="card-body">
                <div className="stepper stepper-links d-flex flex-column pt-15">
                  <div className="mx-auto mw-800px w-100 pt-15 pb-10">
                      <div className="current">
                        <div className="row mb-3">
                          <h3 style={{color: "rgb(99, 160, 75)", textAlign: "center", fontSize: "2.5rem"}}>
                            Welcome,
                          </h3>
                        </div>
                        <div className="row mb-10">
                          <h1 className="feedback-header fw-bold">
                            {eventInfo?.title}
                          </h1>
                        </div>
                        <div className="d-flex mb-5 justify-content-center">
                          <Button
                            type="button"
                            variant="primary"
                            onClick={() => {
                              startSurvey();
                            }}
                            style={{
                              fontSize: "1.80rem",
                              borderRadius: "1.25rem"
                            }}
                          >
                            Start
                          </Button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Toaster
            position="bottom-left"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 3000,
            }}
          />

          <Modal
            open={openPinModal}
            onOk={handleSurveyFormPin}
            confirmLoading={false}
            okText="SUBMIT"
            closable={false}
            centered
            okButtonProps={{ className: 'btn-primary' }}
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <div className="">
              <label className="form-label fs-4 fw-bold">
                Event PIN <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                placeholder="Event PIN"
                className="form-control"
                id="survey-pin"
                onChange={(e) => {
                  setSurveyFormPin(e.target.value);
                }}
                autoComplete="off"
              />
              {(surveyPinError && surveyPinError !== "") && (
                <div className="text-danger text-align-top-center mt-2">
                  {surveyPinError}
                </div>
              )}
            </div>
          </Modal>
        </>
      )}

      {(currentState !== 'pin') && (
        <footer className="footer">
          <ul>
            <li>
              <a href="https://www.getsmoodi.com/" rel="noreferrer" target="_blank">
                <img
                  alt="logo"
                  src={`${toAbsoluteUrl("/Images/smoodi_Logo.png")}`}
                  width="150vw"
                />
              </a>
            </li>
          </ul>
        </footer>
      )}
    </div>
  );
};

export default CreateSurvey;
