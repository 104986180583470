import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import { submitSurvey } from "../../models/_survey";
import { Button } from "react-bootstrap";

const Feedback = (
  props: {
    homeButton: any;
    eventInfo: any;
    feedbackSubmitFc: any;
    setCurrentState: any;
  }
) => {
  const { homeButton } = props;
  const { setCurrentState } = props;
  const { eventInfo } = props;
  const { feedbackSubmitFc } = props;
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    feedback: Yup.string().required("Please enter feedback"),
  });

  return (
    <>
      <div>
        <div className="card mt-9">
          <div className="card-body">
            <div className="d-flex flex-column pt-15">
              <Formik
                validationSchema={validationSchema}
                initialValues={{
                  feedback: ""
                }}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                  const surveyInput: any = localStorage.getItem("survey_input");
                  let inputData = JSON.parse(surveyInput);
                  inputData.feedback = values.feedback;
                  localStorage.setItem("survey_input", JSON.stringify(inputData));
                  if (inputData.questions) {
                    const allQuestions = [];
                    for (const [key, value] of Object.entries(inputData.questions)) {
                      const obj = {
                        question_id: key,
                        answers: typeof value == "object" ? value : [value],
                      };
                      allQuestions.push(obj);
                    }
                    inputData.questions = allQuestions;
                  }
                  await submitSurvey(inputData);
                  localStorage.setItem("current_state", "thankyou");
                  setCurrentState("thankyou");
                  navigate("/thankyou");
                  return true;
                }}
              >
                {({ values, handleChange }) => (
                  <Form
                    className="mx-auto mw-800px w-100 pt-15 pb-10"
                    id="kt_create_account_form"
                  >
                    <div className="current" data-kt-stepper-element="content">
                      <div className="d-flex mb-5 justify-content-center">
                        <Button
                          type="button"
                          variant="secondary"
                          className={`fs-1 btn btn-lg btn-light-primary me-3 px-6 py-2`}
                          onClick={() => {
                            homeButton();
                          }}
                        >
                          Home
                        </Button>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <h1 className="feedback-header fw-bold">Feedback</h1>
                          <div className="question mt-5 pt-5 fs-1 fw-semibold">
                            How can smoodi improve?
                          </div>
                          <div className="mt-5 p-lg-5 p-md-5 p-0">
                            <div className="row justify-content-center align-items-center">
                              <div className="col-xl-8 col-12">
                                <textarea
                                  className="form-control text-area"
                                  rows={8}
                                  cols={75}
                                  maxLength={500}
                                  autoComplete="off"
                                  name="feedback"
                                  placeholder="Type here"
                                  value={values.feedback}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    const surveyInput: any = localStorage.getItem("survey_input");
                                    const inputData = JSON.parse(surveyInput);
                                    inputData.feedback = e.target.value;
                                    localStorage.setItem("survey_input", JSON.stringify(inputData));
                                  }}
                                />
                                <div className="text-danger fs-6 text-center mt-4">
                                  <ErrorMessage name="feedback" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mx-lg-9 pt-15 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="fs-6 btn btn-lg btn-primary me-3 px-6 py-2"
                        onClick={() => {
                          feedbackSubmitFc(eventInfo);
                        }}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
